import React, { Fragment } from 'react';
import { graphql } from "gatsby";

import PrivacyScreen from 'components/screens/PrivacyScreen/PrivacyScreen';

// In theory we could pass in props that we'd fetched via Gatsby's GraphQL
export default ({ data }) => (
  <Fragment>
    <PrivacyScreen data={data}/>
  </Fragment>
);

export const query = graphql`
query GetPrivacy {
    strapiPrivacy {
      Contect
    }
  }
`;
