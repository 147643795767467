import React from 'react';
import { Helmet } from 'react-helmet';
import MarkdownContainer from 'components/elements/MarkdownContainer/MarkdownContainer';


const PrivacyScreen = ({data}) => {

  return (
    <>
      <Helmet
          title="Privacy"
        />
      <div className="px-12 max-w-lg m-auto md:max-w-lg md:px-6 lg:max-w-lg lg:px-8">
        <div className='text-center md:text-left mt-6 mb-16'>
          <h1 className='text-4xl font-bold font-heading uppercase'>Privacy</h1>
        </div>
        
        <div className="grid">
          <MarkdownContainer content= {data.strapiPrivacy.Contect} />
        </div>
        
      </div>
      </>
  );
};

export default PrivacyScreen;